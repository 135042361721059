<template>
  <div class="finance-invoice-review">
    <en-table-layout :tableData="tableData.data">
      <template slot="header">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="待审批" name="1"></el-tab-pane>
          <el-tab-pane label="已审批" name="2"></el-tab-pane>
        </el-tabs>
        <div style="padding:10px 15px">
          <h3>定制折扣率申请流程：</h3>
          <div class="headerStep">
            <p class="stepImage">
              <img src="../../../assets/step/step1.png" alt="">
              <span>销售人员从【客户档案】处提交定制折扣变更申请</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img style="width:66px" src="../../../assets/step/step2.png" alt="">
              <span>部门进行初审</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img style="width:92px" src="../../../assets/step/step3.png" alt="">
              <span>财务人员进行复审</span>
            </p>
            <p class="stepLine">
              <span class="line"></span>
              <span class="arrow-right"></span>
            </p>
            <p class="stepImage">
              <img src="../../../assets/step/step4.png" alt="">
              <span>客户定制折扣率审批通过</span>
            </p>
          </div>
        </div>
      </template>
      <template slot="toolbar">
        <el-form-item v-if="activeName == '2'" label="审批状态" class="col-auto">
          <el-select style="width: 110px" v-model="params.department_audit_query" placeholder="请选择" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="activeName == '1'" label="提交时间" class="col-auto">
          <el-date-picker v-model="order_time_range" type="daterange" style="width: 250px" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="handleDateChange" />
        </el-form-item>
        <el-form-item v-else label="部门审批时间" class="col-auto">
          <el-date-picker v-model="order_time_range" type="daterange" style="width: 250px" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="handleDateChangeTwo" />
        </el-form-item>
        <el-form-item>
          <el-input style="width: 290px;" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" style="width: 100px;">
              <el-option label="客户名称" value="customer_name"></el-option>
              <el-option label="提交人" value="create_user_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button native-type="submit" @click.stop.prevent="searchEvent" size="small" type="primary">搜索</el-button>
        </el-form-item>
      </template>

      <template slot="table-columns" v-if="activeName == '1'">
        <el-table-column prop="name" key="name" show-overflow-tooltip label="所属客户名称" min-width="200" fixed="left" />
        <el-table-column label="申请内容" key="conster" width="150" fixed="left">
          <template>卡券定制折扣变更</template>
        </el-table-column>
        <el-table-column prop="create_time" key="create_time" label="提交时间" width="160" fixed="left">
          <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column key="create_user_name" prop="create_user_name" label="提交人" width="200" />
        <el-table-column key="department_audit_status" prop="status" label="审批状态" width="130">
          <template slot-scope="scope">
            {{ scope.row.department_audit_status | statusFilter }}
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="activeName == '1'" size="mini" type="primary" @click="handleGoodsAudit(scope.row)">
              审核
            </el-button>
            <el-button size="mini" type="primary" @click="openDetail(scope.row)">申请详情
            </el-button>
          </template>
        </el-table-column>
      </template>
      <template v-else slot="table-columns">
        <el-table-column prop="name" show-overflow-tooltip key="name2" label="所属客户名称" fixed="left" />
        <el-table-column label="申请内容" key="conster" width="150" fixed="left">
          <template>卡券定制折扣变更</template>
        </el-table-column>
        <el-table-column prop="create_time2" key="create_time" label="提交时间" width="160" fixed="left">
          <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column key="dept_audit_time2" prop="dept_audit_time" label="部门审批时间" width="160" fixed="left">
          <template slot-scope="scope">{{ scope.row.dept_audit_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column key="create_user_name2" prop="create_user_name" label="提交人" width="200" />
        <el-table-column key="dept_audit_user_name2" prop="dept_audit_user_name" label="部门审批人" width="170" />
        <el-table-column key="department_audit_status2" prop="status" label="审批状态" width="130">
          <template slot-scope="{row}">
            {{ row.department_audit_status | statusFilter }}
            <div v-if="[5, 3].includes(row.department_audit_status)" style="color: red; cursor: pointer"
              @click="showUnderReason(row)">
              (拒绝原因)
            </div>
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="openDetail(scope.row)">申请详情
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>

    <!--审核 dialog-->
    <x-dialog :proxy="examinationDialog">
      <el-form style="padding:20px" :model="goodsAuditForm" ref="goodsAuditForm" label-width="80px">
        <el-form-item label="审核" prop="pass">
          <el-radio-group v-model="goodsAuditForm.department_audit">
            <el-radio :label="2">通过</el-radio>
            <el-radio :label="1">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="goodsAuditForm.department_audit === 1" label="拒绝原因" prop="refuse_reason">
          <el-input v-model.trim="goodsAuditForm.refuse_reason" maxlength="100" type="textarea" :rows="5"
            placeholder="拒绝原因最多不超过100字。" />
        </el-form-item>
      </el-form>
    </x-dialog>
    <cardcheck ref="cardCheck"></cardcheck>
    <el-dialog-x-dialog title="拒绝原因" :visible.sync="isShowUnderReason" width="17%">
      <div align="center" style="padding: 10px;">{{ under_reason ? under_reason : "无" }}</div>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { getDiscountRateAuditList, discountRateAuditAuditing } from '@/api/settingCustomer';
import cardcheck from './components/cardCheck';
export default {
  name: 'departmentalApprovalListDZ',
  components: { EnTableLayout, XDialog, cardcheck },
  data () {
    return {
      activeName: '1',
      examinationDialog: $xDialog.create({
        title: "审核操作",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.submitGoodsAuditForm
      }),
      options: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '4',
          label: '待财务复审'
        },
        {
          value: '2',
          label: '审批完成'
        },
        {
          value: '5',
          label: '部门审批拒绝'
        },
        {
          value: '3',
          label: '财务复审拒绝'
        }
      ],
      order_time_range: [],
      // 列表loading状态
      loading: false,
      key_word: 'customer_name',
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        department_audit_query: "",//审批状态
        keyWord: "",
      },
      // 列表数据
      tableData: {},
      // 审核商品 表单
      goodsAuditForm: {
        id: '',
        department_audit: 2
      },
      isShowUnderReason: false,//拒绝原因弹窗
      under_reason: '',
    };
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        0: '待部门审核',
        4: '待财务复审',
        2: '审批完成',
        5: '部门审批拒绝',
        3: '财务复审拒绝',
      }
      return statusMap[status]
    }
  },
  mounted () {
    this.GET_GoodsAuditList();
  },
  methods: {
    /** 显示拒绝原因 */
    showUnderReason (row) {
      this.isShowUnderReason = true;
      this.under_reason = row.refuse_reason;
    },
    handleClick () {
      this.params.department_audit_query = '';
      this.tableData = { data: [] };
      this.params = {
        page_no: 1,
        page_size: 20,
        department_audit_query: "",//审批状态
        keyWord: "",
      },
        this.order_time_range = [];
      this.GET_GoodsAuditList();
      this.$nextTick((res) => {
        this.$refs.enTableLayout.$refs.table.doLayout();
      });
    },
    openDetail (row) {
      this.$refs.cardCheck.show(row);
    },
    /** 搜索事件触发 */
    searchEvent (data) {
      this.params.page_no = 1;
      this.GET_GoodsAuditList();
    },
    //格式化时间控件 提交时间
    handleDateChange () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.start_time =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.end_time =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_time =
            this.order_time_range[0].getTime() / 1000;
          this.params.end_time =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.start_time = "";
        this.params.end_time = "";
      }
    },
    //部门审批时间
    handleDateChangeTwo () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.department_start_time =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.department_end_time =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.department_start_time =
            this.order_time_range[0].getTime() / 1000;
          this.params.department_end_time =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.department_start_time = "";
        this.params.department_end_time = "";
      }
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsAuditList();
    },

    /** 打开审核商品 */
    handleGoodsAudit (row) {
      this.goodsAuditForm = {
        id: row.id,
        department_audit: 2,
      }
      this.examinationDialog.display();
    },

    /** 审核商品 表单提交 */
    submitGoodsAuditForm () {
      let { promise, resolve, reject } = Promise.withResolvers();
      discountRateAuditAuditing(this.goodsAuditForm).then(response => {
        if(response==true){
          this.$message.error('卡券所属模板已被删除，已自动审批拒绝');
        }else{
          this.$message.success('审核完成！');
        }
        this.GET_GoodsAuditList();
        resolve(true)
      }).catch(error => {
        reject(false)
      });
      return promise
    },

    /** 获取列表数据 */
    GET_GoodsAuditList () {
      this.loading = true;
      let params = {
        ...this.params
      };
      params[this.key_word] = params.keyWord
      delete params.keyWord
      if (this.activeName == '1') {//待审批
        params.department_audit_query = 0
      } else {
        params.department_audit_query == '' ? params.department_audit_query = 1 : params.department_audit_query
      }
      getDiscountRateAuditList(params)
        .then(response => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => (this.loading = false));
    },
  }
};
</script>

<style lang="scss" scoped>
.goods-image {
  width: 50px;
  height: 50px;
}

.headerStep {
  display: flex;

  p {
    margin: 0
  }

  .stepImage {
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: center;
    align-items: center;

    img {
      width: 70px;
    }

    span {
      margin-top: 15px;
    }
  }

  .stepLine {
    display: flex;
    align-items: center;
    margin: 0 10px 39px;

    .line {
      display: inline-block;
      width: 80px;
      height: 4px;
      background: #333;
    }

    .arrow-right {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 10px solid #333;
    }
  }
}

.goods-picker-title {
  border-bottom: 1px solid #ccc;
  height: 51px;
  line-height: 51px;
  text-align: left;
  background-color: rgba(224, 228, 238, 1);
  font-weight: 600;
  border-radius: 5px;
}

.goods-picker-title span {
  background-color: #1a43a9;
  width: 4px;
  height: 14px;
  display: inline-block;
  margin-right: 14px;
  margin-bottom: 3px;
}

.el-radio {
  margin-top: 6px;
}

.fileName {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
</style>
